import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PieChartIcon from '@mui/icons-material/PieChart';
import HandshakeIcon from '@mui/icons-material/Handshake';
import FeedIcon from '@mui/icons-material/Feed';
import ForumIcon from '@mui/icons-material/Forum';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import WarningIcon from '@mui/icons-material/Warning';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
const DEV_SERVER = false;
// if(!__DEV__) {
//   DEV_SERVER = false;
// }
let token = "";
const SERVER_ADDRESS = (DEV_SERVER)?"http://192.168.1.10:3002/panel/":"https://server.tasimacim.com/panel/";

const s ={}
s.SOCKET_ADDRESS = "https://server.tasimacim.com:4242"//DEV_SERVER?"http://192.168.1.101:4242":"https://server.tasimacim.com:4242";
s.SERVER_ADDRESS = SERVER_ADDRESS;

s.NAVIGATIONS = [
    
  {
      title: "GENEL"
  }, 
  {
  name: "Anasayfa",
  href: "/",
  icon: <HomeIcon />,
  permission: 4
},
{
  name: "Lojistik",
  href: "/logistics",
  icon: <LocalShippingIcon />,
  permission: 1
},
{
  name: "Esnek",
  href: "/flex",
  icon: <LocalShippingIcon />,
  permission: 1
},
{
  name: "Finans",
  href: "/finance",
  icon: <PieChartIcon />,
  permission: 5
},
{
  name: "Canlı Harita",
  href: "/livemap",
  icon: <MapIcon />,
  permission: 1
},
{
  name: "Bildirim Merkezi",
  href: "/notifications",
  icon: <NotificationsActiveIcon />,
  permission: 5
},
{
  title: "SÜRÜCÜ ORTAKLARI"
}, 
{
  name: "Sürücüler",
  href: "/drivers",
  icon: <HandshakeIcon />,
  permission: 2
},
// {
//     name: "Sürücüler",
//     href: "/drivers",
//     icon: <HandshakeIcon />,
//     menu: [
//         {
//             name: "Finans",
//             href: "/finance",
//             icon: <PieChartIcon />
//         }
//     ]
// },
{
  name: "Başvurular",
  href: "/applications",
  icon: <FeedIcon />,
  permission: 3
},
{
  title: "DESTEK MERKEZİ"
},
{
  name: "Canlı Yardım",
  href: "/livesupport",
  icon: <ForumIcon />,
  permission: 6
},
{
  name: "Hızlı Yanıtlar",
  href: "/replies",
  icon: <ForumIcon />,
  permission: 6
},
{
  name: "Uyarılar",
  href: "/warnings",
  icon: <WarningIcon />,
  permission: 6
},
{
  title: "CMS"
},
{
  name: "Sürücü Duyuruları",
  href: "/driverannouncements",
  icon: <TextSnippetIcon />,
  permission: 6
}
,
{
  name: "Sipariş Oluştur",
  href: "/createorder",
  icon: <TextSnippetIcon />,
  permission: 6
}

]

s.getTransactionDescription = (transaction) => {
  if(transaction.type == 0) {
    return "Sipariş Komisyonu (#" + transaction.data.order.substring(18).toUpperCase() + ")";
  } else if(transaction.type == 1) {
    if(!transaction.data || !transaction.data.order) return "Hoşgeldin Bonusu";

    return "KK Sipariş Ödemesi (#" + transaction.data.order.substring(18).toUpperCase() + ")";
  } else if(transaction.type == 5) {
    return "Bakiye Çekme"
  } else if(transaction.type == 4) {
    return "KK Bakiye Yatırma"
  } else if(transaction.type == 6) {
    return "Panel Bakiye " + ((transaction.value > 0)?"Yatırma":"Çekme") + " (" + transaction.data.description + ")";
  } else if(transaction.type == 2) {
    return "Bahşiş"
  } else if(transaction.type == 7) {
    return "Cari Ödeme"
  }
}


s.fetchClient = (url, body,ai) => {
  return new Promise(resolve => {
      //getToken().then(token => {
        
        //fetch('http://192.168.1.7:3000/' + url, {body: JSON.stringify(body), method:"POST",mode:"no-cors", headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+token}}).then((e) => e.json()).then((e) => {
          
        // if(!body) body = {}
        // body.endpoint = url;
        // console.log(body);
        

        //http://192.168.1.104:3002/
        //fetch(DEV_SERVER?("http://35.241.204.128/" + url):('https://europe-west1-windy-collector-346317.cloudfunctions.net/handler?q='+url), {body: JSON.stringify(body), method:"POST",mode:"no-cors", headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+token}}).then((e) => e.json()).then((e) => {
        
        //console.log(SERVER_ADDRESS+url);
        fetch("https://server.tasimacim.com/" + url, {body: JSON.stringify(body), method:"POST",  headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+token}}).then((e) => e.json()).then(async (e) => {
            
        if(e.token) {
          token = e.token;
          s.token = token;
          await global.setItem("bearerToken", e.token);
          //await localStorage.setItem("bearerToken", e.token);
          
        }
        

        if(e.user) {

          
          s.user = e.user;

   

          
        }

        if(e.error) {
          //global.showError(e.error);
        }
        resolve(e)

        
        
  }).catch((e) => {
    console.log(e);
    //global.showError(global.getText("connectionError"));
  });
      //})
    
  })
}

s.sendFile = (url, formData) => {
  return new Promise(resolve => {
    //getToken().then(token => {
      
      //fetch('http://192.168.1.7:3000/' + url, {body: JSON.stringify(body), method:"POST",mode:"no-cors", headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+token}}).then((e) => e.json()).then((e) => {
        
      // if(!body) body = {}
      // body.endpoint = url;
      // console.log(body);
      

      //http://192.168.1.104:3002/
      //fetch(DEV_SERVER?("http://35.241.204.128/" + url):('https://europe-west1-windy-collector-346317.cloudfunctions.net/handler?q='+url), {body: JSON.stringify(body), method:"POST",mode:"no-cors", headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+token}}).then((e) => e.json()).then((e) => {
      
      //console.log(SERVER_ADDRESS+url);
      

      console.log(formData.get("file"));
      fetch((SERVER_ADDRESS)+url, {body: formData, method:"POST",  headers: { Authorization: 'Bearer '+token}}).then((e) => e.json()).then(async (e) => {
          
     
      resolve(e)

      
      
  }).catch((e) => {
    console.log(e);
    //global.showError(global.getText("connectionError"));
  });
      //})
    
  })
}

s.fetch = (url, body,ai) => {
  return new Promise(resolve => {
      //getToken().then(token => {
        
        //fetch('http://192.168.1.7:3000/' + url, {body: JSON.stringify(body), method:"POST",mode:"no-cors", headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+token}}).then((e) => e.json()).then((e) => {
          
        // if(!body) body = {}
        // body.endpoint = url;
        // console.log(body);
        

        //http://192.168.1.104:3002/
        //fetch(DEV_SERVER?("http://35.241.204.128/" + url):('https://europe-west1-windy-collector-346317.cloudfunctions.net/handler?q='+url), {body: JSON.stringify(body), method:"POST",mode:"no-cors", headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+token}}).then((e) => e.json()).then((e) => {
        
        //console.log(SERVER_ADDRESS+url);
        fetch((ai?"https://ai.tasimacim.com/":SERVER_ADDRESS)+url, {body: JSON.stringify(body), method:"POST",  headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+token}}).then((e) => e.json()).then(async (e) => {
            
        if(e.token) {
          token = e.token;
          s.token = token;
          await global.setItem("bearerToken", e.token);
          //await localStorage.setItem("bearerToken", e.token);
          
        }
        

        if(e.user) {

          
          s.user = e.user;

   

          
        }

        if(e.error) {
          //global.showError(e.error);
        }
        resolve(e)

        
        
  }).catch((e) => {
    console.log(e);
    //global.showError(global.getText("connectionError"));
  });
      //})
    
  })
}

s.getPlatformEmoji = (platform) => platform == 0?"🍎":platform == 1?"🤖":platform==2?"🌐": platform==5?"🧿":"🤷";


const citiesById = ['','Adana', 'Adıyaman', 'Afyon', 'Ağrı', 'Amasya', 'Ankara', 'Antalya', 'Artvin',
'Aydın', 'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa', 'Çanakkale',
'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir',
'Gaziantep', 'Giresun', 'Gümüşhane', 'Hakkari', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir', 
'Kars', 'Kastamonu', 'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya', 'Kütahya', 'Malatya', 
'Manisa', 'Kahramanmaraş', 'Mardin', 'Muğla', 'Muş', 'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya',
'Samsun', 'Siirt', 'Sinop', 'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak',
'Van', 'Yozgat', 'Zonguldak', 'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale', 'Batman', 'Şırnak',
'Bartın', 'Ardahan', 'Iğdır', 'Yalova', 'Karabük', 'Kilis', 'Osmaniye', 'Düzce'];
s.getCityName = (id) => citiesById[id];

s.getAddressLabel = (address) => {
  var extras = "";
  if(address) {
    // if(address.extra.buildingNO) extras += address.extra.buildingNO + " ";
    // if(address.extra.floorNO) extras += address.extra.floorNO + " ";
    if(address.building) extras += "Bina: "+address.building + " ";
    if(address.floor) extras += "Kat: "+address.floor + " ";
    if(address.door) extras += "No: "+address.door + " ";

    if(address.details) extras += "Müşteri Notu: " + address.details;
    extras = extras.replace(/\s{2,}/g, ' ').trim();
  }

  return address.Label + ((extras.length > 0)?" · "+extras:"");
}

export default s;