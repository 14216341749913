import React from 'react';
import { Typography, Button, Avatar, Menu,TextField, Select, MenuItem,CircularProgress, Backdrop, Tooltip, TextareaAutosize } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import API from '../API';
import dayjs, { Dayjs } from 'dayjs';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ErrorIcon from '@mui/icons-material/Error';
import { Document, Page } from 'react-pdf';
import { trTR } from '@mui/x-date-pickers/locales';
import { Link } from 'react-router-dom';
import {tr} from 'date-fns/locale'
import Rating from '@mui/material/Rating';
import "dayjs/locale/tr";
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import ReactAudioPlayer from 'react-audio-player';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet'
import Review from '../components/Review';
import TimetableComponent from '../components/TimetableComponent';
import ItemDescription from '../components/ItemDesc';
import JYSKLogo from '../components/JYSKLogo';
import JYSKTimetableComponent from '../components/JYSKTimetableComponent';
import SearchBarComponent from '../components/SearchBarComponent';
import ForumIcon from '@mui/icons-material/Forum';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// import convertHTMLToPDF from 'pdf-puppeteer';
// const FILES = ["Sürücü Belgesi Ön", "Sürücü Belgesi Arka", "İkametgah Belgesi", "Adli Sicil Kaydı", "Profil Fotoğrafı"];
const VEHICLE_NAMES = ["Motorsiklet", "Sedan", "Doblo", "Minivan", "Panelvan", "Kamyonet"];
const VEHICLE_TIMES = [5,15,15,15,20,30]
var IN_EDIT_MODE = false;

const greenIcon = new L.Icon({
    iconUrl:
      "http://maps.google.com/mapfiles/ms/icons/green.png",
    iconAnchor: [16,32],
    iconSize:[32,32]
  });


//TEST

var STATUS = {};
STATUS[0] = {
    t: "Sürücü Aranıyor",
    color: "rgb(80,72,229)"
}
STATUS[1] = {
    t: "Planlanmış",
    color: "rgb(80,72,229)"
}
STATUS[2] = {
    t: "Aktif Sipariş",
    color: "orange"
}
STATUS[3] = {
    t: "Tarih Kesinleştirildi",
    color: "rgb(80,72,229)"
}
STATUS[10] = {
    t: "Tamamlanmış",
    color: "green"
}
STATUS[11] = {
    t: "Müşteri İptal",
    color: "yellow"
}
STATUS[-1] = STATUS[11];
STATUS[12] = {
    t: "Sürücü İptali",
    color:"crimson"
}

const orderCancelReasons = ["Yanlışlıkla aldım", 'Trafik', 'Aracıma sığmadı', 'Kaza veya doğal afet', 'Diğer'];

const AcqusitionTypes = {}
AcqusitionTypes[-1] = "Diğer"
AcqusitionTypes[0] = "Instagram"
AcqusitionTypes[1] = "Facebook"
AcqusitionTypes[2] = "Google"
AcqusitionTypes[3] = "Arkadaş/Öneri"
AcqusitionTypes[4] = "Uygulama Mağazası"


class Recording extends React.Component {
    constructor(props) {
        super(props);

        this.state = {count: 0, loading: false, url: undefined}
    }

    render() {

        const callUpdate = this.props.order.updates?.find(s => s.callId == this.props.history.data.call);
      
        return <div style={{display:"flex", flexDirection:"column"}}>

            <div style={{display:"flex", alignItems:"center"}}>
            <a href={"https://oim.verimor.com.tr/switch/reports/cdrs/" + this.props.history.data.call} target="_blank"><Typography style={{marginRight:"5px"}}>{(this.props.history.data.p?"Sürücü":"Müşteri")+" Araması"}</Typography></a>
            {!this.state.loading && this.state.url == undefined && <a href="#" onClick={() => {
                
                
                this.setState({loading: true})
                
                API.fetch("getrecording", {id: this.props.history.data.call}).then(e => {
                    console.log(e);

                    if(e.url) {
                        this.setState({loading: false, url: e.url})
                    } else {
                        this.setState({loading: false});
                    }
                })
            }}>Dinle </a>}

            
            {this.state.url != undefined && !this.state.loading && <ReactAudioPlayer style={{display:"inline", maxWidth:"100%"}} autoPlay controls src={this.state.url} />}
            {/* {this.state.url != undefined && !this.state.loading && <div><audio style={{display:"inline",maxWidth:"100%"}} autoPlay controls><source src={this.state.url}  /></audio></div>} */}
            {this.state.loading && <CircularProgress size={18} />}

            {callUpdate && <div style={{borderRadius:10, padding:8, paddingTop:2, paddingBottom:2,width:"fit-content",backgroundColor:((callUpdate.callStatus == 10)?"green":callUpdate.callStatus==9?"red":"rgb(80,72,229)"), marginLeft:7}}><Typography style={{fontWeight:"bold"}} fontSize={11} color="white">{callUpdate.callResultStr || "İşlemde"}{callUpdate.callSummary?.durationInSeconds != undefined?" "+secondsToString(callUpdate.callSummary.durationInSeconds):""}</Typography></div>}
            {callUpdate && <a style={{marginLeft:5}} href="#" onClick={() => {
            window.open("../transcript/" + callUpdate._id,'popup','width=400,height=600')
        }}>Transkript</a>}
            <Typography style={{marginLeft:5}}>{new Date(this.props.history.d).toLocaleString("tr-TR")}</Typography>
            </div>

            {/* {callUpdate != undefined && callUpdate.callSummary && <div style={{display:"flex", flex:1}}>
                
                <Typography>{callUpdate.callSummary.summary}</Typography>    
                
            </div>} */}
            
        </div>
    }
}

var labelChecks = [
    (history,order) => {
        if(history.data && history.data._p && history.status == 1) {
            
            var id = 0;
            
            for(var i = 0; i < order.history.length; i++) {
                if(order.history[i].status == 1 && order.history[i].data && order.history[i].data._p) {
                    

                    if(order.history[i] == history) break;
                    id++;
                }
            }  

            
            return {label: "Sürücü Atandı", p: "../messages/" + order._id +"/"+ id, link: "../driver/" + history.data._p,customElement:  
            <div style={{display:"flex",gap:5,alignItems:"center"}}>
                <a href={'../driver/'+ history.data._p}><Typography>{history.data._p.substring(18).toUpperCase()}</Typography></a>
                <a href="#" onClick={(e) => {
                     window.open("../messages/" + order._id +"/"+ id,'popup','width=400,height=600')
                     e.preventDefault();
                }}><Typography>Mesajları Gör</Typography></a>
            <div style={{borderRadius:10, padding:8, paddingTop:2, paddingBottom:2,width:"fit-content",backgroundColor:((history.data.messageCount > 0)?"green":"red"),alignItems:"center",justifyContent:"center",height:"fit-content"}}><Typography style={{fontWeight:"bold"}} fontSize={11} color="white">{history.data.messageCount} Mesaj</Typography></div>
            <Typography>{new Date(history.d).toLocaleString("tr-TR")}</Typography>
            </div>, linkname: history.data._p.substring(18).toUpperCase()}

        }

        return undefined;
    },
    (history,order) => {
        if(history.data && history.data._p && history.status == 0) {
            const foundUpdate = order.updates.find(s => s.driverId == history.data._p && s.type == 1);

            return {label: "Sürücü İptali", 
            alt: foundUpdate?<div style={{borderRadius:10, padding:8, paddingTop:2, paddingBottom:2,backgroundColor:'red', marginLeft:7,width:"fit-content",height:"fit-content"}}><Typography style={{fontWeight:"bold"}} fontSize={11} color="white">{orderCancelReasons[foundUpdate.reason]}</Typography></div>:undefined,
            link: "../driver/" + history.data._p, linkname: history.data._p.substring(18).toUpperCase()}
        }

        return undefined;
    },
    (history) => {
        if(history.data && history.status == -1) return {label: "Müşteri İptali"}

        return undefined;
    },
    (history) => {
        if(history.data && !history.status && history.data == 2) return {label: "Sürücü Yola Çıktı"}

        return undefined;
    },
    (history) => {
        if(history.data && !history.status && history.data.currentWaypoint != undefined) return {label: (history.data.nearLocation)?"Sürücü Yakın":"Sürücü Vardı"}

        return undefined;
    },
    (history) => {
        if(history.data && history.data.pictureSent) return {label: "Fotoğraf Yüklendi", link: "https://tasimacim-order-pictures.s3.eu-central-1.amazonaws.com/" + history.data.pictureSent, linkname: "Gör"}

        return undefined;
    },
    (history, order) => {
        if(history.data && history.data.call) return {label: (history.data.p?"Sürücü":"Müşteri")+" Araması",altComponent: getAltComponentSummary(order, history), customElement: <Recording order={order} history={history} />,link: "https://oim.verimor.com.tr/switch/reports/cdrs/" + history.data.call, linkname: "Dinle"}
    },
    (history) => {
        if(history.status == 3) return {label: "Tarih Kesinleş."};

        return undefined
    },
    (history) => {
        if(history.data && history.data.payment && history.status == undefined) return {label: "Ödeme Yapıldı"};

        return undefined;
    },
    (history) => {
        console.log(history);
        if(history.data && history.data.description) return {label: "Yetkili Gelişme ("+history.data.workerName+")", altComponent: <Typography style={{marginTop:10}}>{history.data.description}</Typography>};

        return undefined;
    }
]


function getAltComponentSummary(order, history) {
    var callUpdate = order.updates?.find(s => s.callId == history.data.call)

   
    if(callUpdate != undefined && callUpdate.callSummary != undefined){
        
        
        return <div style={{display:"flex", flex:1,marginTop:10}}>
                
                <Typography>{callUpdate.callSummary.summary}</Typography>    
                
            </div>

}

    return undefined;
            
}

const options = ["Test", "another test"];
var ADDITIONALS = ["Ön koltukta refakat", "Evcil hayvanım var", "Üstü kapalı kamyonet", "Sürücünün yardımı", "Yardımcı", "Montaj/Demontaj", "Paketleme"]
export default class OrderScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: true,editing: false, saving: false, approveDriverDialog: false, backdropVisible: false,actionsDropdownOpen: false, assignDriverModalOpen: false, addHistoryUpdateModalOpen: false, addHistoryUpdateText: ""};

        
        var id = props.match.params.id;

        
        if(!id) props.history.goBack();

        
    }

    handleClick() {
        console.info(`You clicked ${options[0]}`);
    };
    
    handleMenuItemClick(event,index) {
        setSelectedIndex(index);
        this.setState({actionsDropdownOpen: false});
    }
    
    handleToggle() {
        this.setState({actionsDropdownOpen: !this.state.actionsDropdownOpen});
    }
    
    handleClose(event) {
        if (
          this.anchorRef.current &&
          this.anchorRef.current.contains(event.target)
        ) {
          return;
        }
    
        this.setState({actionsDropdownOpen: false});
    }

    componentDidMount() {
        var id = this.props.match.params.id;
        
        API.fetch("getorder", {_id: id}).then((e) => {
           
            if(e.error) return this.props.history.goBack();
            else {
                this.polyLine = (e.locations != null && e.locations.length > 0)?e.locations.map(s => ([s.l[1], s.l[0]])):undefined;
            }


            

                console.log(e);
            

                var arr = [];

                arr.push([e.from.coordinates[1],e.from.coordinates[0]]);
                e.waypoints.forEach(waypoint => arr.push([waypoint.coordinates[1],waypoint.coordinates[0]]));
                arr.push([e.to.coordinates[1],e.to.coordinates[0]]);

                
                if((e.status == 2 || e.status == 10) && e.locations != undefined && e.locations.length > 0) {
                    arr.push([e.locations[e.locations.length-1].l[1],e.locations[e.locations.length-1].l[0]])
                } else if(e.driverLastLocation) {
                    arr.push([e.driverLastLocation.coordinates[1],e.driverLastLocation.coordinates[0]])
                }
        


                this.mapBounds = arr;
                
            
            
            this.setState({order: e, loading: false});
        })

        

        
        // API.fetch('getapplications').then((e) => {
        //     if(!e.error) this.setState({applications: e});
        // })
    }
    render() {

        
        
        IN_EDIT_MODE = this.state.editing;

        if(this.state.loading) return <></>


        var timeMinutes = this.state.order.time*60000;

        var time = convertMillisToTimeString(timeMinutes)  + " ("+convertMillisToTimeString(VEHICLE_TIMES[this.state.order.vehicle]*(2+this.state.order.waypoints.length)*60000)+" yükleme boşaltma)"
       

        var baseFee = parseFloat(this.state.order.pricing.price);
        baseFee -= this.state.order.pricing.commision;
        this.state.order.pricing.bridges.forEach((s) => baseFee -= s.price);
        for(var i = 0; i < this.state.order.additionals.length; i++) {
            
            if(this.state.order.additionals[i]) baseFee -= this.state.order.additionalPrices[i] * .85;
        }

        var timePassed = "";
        if(this.state.order.status > 1 && this.state.order.status != 3) {
           
           
            for(var i = this.state.order.updates.length-1; i >= 0; i--) {
                const update = this.state.order.updates[i];

                if(update.type == 9 && update.waypoint == 0) {

                    var referenceEndTime = Date.now();

                    if(this.state.order.status == 10) {
                        var closeUpdate = this.state.order.updates.find(s => s.type == 10 && s.isPickup == false);
                        if(closeUpdate) referenceEndTime = parseInt(closeUpdate._id.substring(0, 8), 16) * 1000;
                    }

                    var millisecondsPassed = (referenceEndTime-parseInt(update._id.substring(0, 8), 16) * 1000);
                    timePassed = convertMillisToTimeString(millisecondsPassed);

                    if(this.state.order.vehicle != 6) {
                        var timeDifference = (this.state.order.time*60000)-millisecondsPassed;

                        if(timeDifference >= 0) {
                            timePassed += " (Servis süresi aşımına " + convertMillisToTimeString(timeDifference) + ")";
                        } else {
                            timePassed += " (Servis süresinden " + convertMillisToTimeString(-timeDifference) + " fazla)";
                        }
                    }



                    break;
                }
            }
        }
        
        var lastLocationUpdateDate = undefined;
        if((this.state.order.status == 2 || this.state.order.status == 10) && this.state.order.locations != undefined && this.state.order.locations.length > 0) {
           
            lastLocationUpdateDate = new Date(this.state.order.locations[this.state.order.locations.length-1].time);
        } else if(this.state.order.driverLastLocation) {
            lastLocationUpdateDate = new Date(this.state.order.driverLastLocation.time);
        }

        return <div style={{display:"flex", justifyContent:"center"}}>

            <div style={{maxWidth:900, width:"100%"}}>
            <div style={{marginTop:40, display:"flex", justifyContent:"end", alignItems:"center",flexWrap:"wrap",gap:10}}>
                <div style={{display:"flex",flex:1}}>
                    {/* <Avatar sx={{width:70, height:70}} src={"https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/"+this.state.driver._id+".jpeg"} /> */}
                    <div>
                        <Typography variant='h4' style={{display:"flex",alignItems:"center",gap:5}}>Sipariş #{this.state.order._id.toString().substring(18).toUpperCase()}{this.state.order.kioskId != undefined && <JYSKLogo style={{aspectRatio:"31/14",width:60}} />}</Typography>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <Typography variant='body2'>sipariş_tarihi: </Typography>
                            <div style={{marginLeft:10, backgroundColor:"#E5E7EB", borderRadius:50, paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2}}>
                                <Typography style={{fontWeight:"600"}} variant='body2'>{new Date(parseInt(this.state.order._id.substring(0, 8), 16) * 1000).toLocaleString("tr-TR")}</Typography>
                            </div>
                        </div>
                        

                    </div>
                </div>
                <div style={{alignItems:"flex-end"}}>

                {this.state.editing && <Button onClick={() => {

                        
this.setState({editing: false, order: this.orderOld});
}} style={{marginRight:20}} color='error' variant='outlined' >İptal <CloseIcon sx={{width:18, height:18}} style={{marginLeft:10}} /></Button>}
{/* {!this.state.editing && <Button onClick={() => this.setState({approveDriverDialog: true})} variant='contained'>Başvuruyu Onayla</Button>} */}


                    {!this.state.editing && <Button onClick={() => {

                        this.orderOld = JSON.parse(JSON.stringify(this.state.order));
                        this.setState({editing: true});
                    }} style={{marginRight:20}} variant='outlined'>Düzenle <EditIcon sx={{width:18, height:18}} style={{marginLeft:10}} /></Button>}

<ButtonGroup variant="contained" ref={a => this.anchorRef = a} aria-label="split button">
        
        <Button
          size="small"
          aria-controls={this.state.actionsDropdownOpen ? 'split-button-menu' : undefined}
          aria-expanded={this.state.actionsDropdownOpen ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={() => this.handleToggle()}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={this.state.actionsDropdownOpen}
        
        anchorEl={this.anchorRef}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={(e) => this.handleClose(e)}>
                <MenuList id="split-button-menu" autoFocusItem>
                    {this.state.order.status != -1 && this.state.order.status != 10 && <MenuItem onClick={() => {

this.handleClose();
global.openPrompt("Siparişi iptal et?",'Siparişi iptal etmek istediğinizden emin misiniz?', 'Onayla',() => {
    this.setState({backdropVisible: true});

                        API.fetch("cancelorder", {_id: this.state.order._id}).then((e) => {
                            console.log(e);
                            if(!e.error) {
                                this.state.order.status = -1;
                                delete this.state.order.driver;
                            }

                            this.setState({backdropVisible: false});

                            
                        })
})
                        
                        
                    }}>Siparişi iptal et</MenuItem>}


                    {(this.state.order.status == 2 || this.state.order.status == 3) && <MenuItem onClick={() => {

this.handleClose();
global.openPrompt("Planlanmışa çevir?",'Siparişi planlanmışa çevirmek istediğinizden emin misiniz?', 'Onayla',() => {
    this.setState({backdropVisible: true});

                        API.fetch("setorderstatus", {_id: this.state.order._id}).then((e) => {
                            
                            if(!e.error) {
                                this.state.order.status = 1;
                                delete this.state.order.currentWaypoint;
                            }

                            this.setState({backdropVisible: false});

                            
                        })
})
                        
                    }}>Planlanmışa çevir</MenuItem>}

                    {this.state.order.status >= 1 && this.state.order.status < 10 && <MenuItem onClick={() => {
 
  this.handleClose()
                        global.openPrompt("Sürücü iptali?",'Sürücü iptali yapmak istediğinizden emin misiniz?', 'Onayla',() => {
                            this.setState({backdropVisible: true});

                            API.fetch("releaseorder", {_id: this.state.order._id}).then((e) => {
                                if(!e.error) {
                                    this.state.order.status = 0;
                                    delete this.state.order.driver;
                                }
    
                                this.setState({backdropVisible: false});
                            })
                        })
                        

}}>Sürücü iptali</MenuItem>}

{this.state.order.status >= 0 && this.state.order.status < 10 && <MenuItem onClick={() => {
                        this.setState({assignDriverModalOpen: true});
                        this.handleClose();

}}>Sürücü ata</MenuItem>}
                  
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
                   
                </div>

                
            </div>

                {/* <div style={{display:"flex",marginTop:24}}>
                    <LogisticAnalytic backgroundColor={'rgba(80,72,229,.1)'} first title={"Son 7 günde sipariş"} value={this.state.driver.orders.length} icon={<LocalShippingIcon sx={{color: 'rgb(80,72,229)'}} />} />
                    <LogisticAnalytic backgroundColor={"rgba(134,197,82,.1)"} title={"Güncel bakiye"} value={(this.state.driver.credits+this.state.driver.freeCredits) +(this.state.driver.freeCredits > 0?" ("+this.state.driver.credits+")":"")} icon={<AccountBalanceWalletIcon sx={{color: 'rgb(134,197,82)'}} />} />
                    <LogisticAnalytic backgroundColor={"rgba(255,176,32,.1)"} title={"7 günde iptal"} value={(this.state.driver.canceledOrderCount)} icon={<ErrorIcon sx={{color: 'rgb(255,176,32)'}} />} />
                
                    
                </div> */}

<Badge style={{marginTop:24,padding:0}}>
                <div style={{padding:0}}>
                <Typography style={{marginBottom:20, marginLeft:24, marginTop:24}} variant='h6'>Detaylar</Typography>
                
                <DetailText editable={false} link={"../client/"+this.state.order.clientId} first label={"Müşteri"} value={this.state.order.clientName + (this.state.order.business?` (${this.state.order.business.name}) `:"") +" " + API.getPlatformEmoji(this.state.order.platform)} />
                
                <DetailText centerLabel editable={false} label={"Bakan Yetkili"}>
                    <div style={{display:"flex",flexDirection:"row", gap: 10,alignItems:"center"}}>
                        <Typography>{this.state.order.assignedWorker == undefined?"Bakan bir yetkili yok":this.state.order.assignedWorker.name}</Typography>
                        {this.state.order.assignedWorker?.name != API.user.name && <Button size='small' onClick={()=> {
                             global.openPrompt("Sipariş üstüne almak istediğinden emin misin?", "", "Onayla", () => {
                                global.showLoadingPanel();

                                API.fetch('assignworker',{_id: this.state.order._id, oldWorker: this.state.order.assignedWorker != undefined?this.state.order.assignedWorker._id:undefined}).then(e => {
                                    global.hideLoadingPanel();

                                    if(e.error) {
                                        global.showPopup("Hata", e.error);
                                    } else {
                                        this.state.order.assignedWorker = e.assignedWorker;
                                        this.forceUpdate();
                                    }
                                });
                             });
                        }} >Üstüne Al</Button>}
                    </div>
                </DetailText>
                
                {this.state.order.vehicle != 6 && <>
                <DetailText editable={true} customEdit={() =><LocalizationProvider adapterLocale='tr' dateAdapter={AdapterDayjs}> <DateTimePicker  ampm={false} defaultValue={dayjs(this.state.order.date)} onChange={(e) => this.state.order.date = e.$d.getTime()} /> </LocalizationProvider>} label={"Ayarlanmış Tarih"} value={new Date(this.state.order.date).toLocaleString("tr-TR")} />
                <DetailText editable={false} label={"Araç"} value={VEHICLE_NAMES[this.state.order.vehicle]} />
                </>}
                <DetailText editable={false} label={"Durum"} value={(this.state.order.status == 1 && this.state.order.vehicle == 6)?"Sürücü Bulundu":STATUS[this.state.order.status].t} />
                <DetailText  label={"Not"} onChange={(e) => {this.state.order.note = e; this.forceUpdate()}} value={this.state.order.note} />
                <Waypoint title={0} waypoint={this.state.order.from} />
                {this.state.order.waypoints.map((x, i) => <Waypoint key={i} title={i+1} waypoint={x} />)}
                <Waypoint title={this.state.order.waypoints.length+1} waypoint={this.state.order.to} />
              

                {this.state.order.driver != undefined && <DetailText editable={false}  label={"Sürücü"}  value={<div style={{display:"flex",gap:10,alignItems:"center"}}><Link to={"../driver/" + this.state.order.driver} ><Typography>{this.state.order.driverName}</Typography></Link>
                <a href="#" style={{fill: "#3c9cd7"}} onClick={() => {
                   
            window.open('../livesupport/'+this.state.order.driver + "?alt="+encodeURIComponent(JSON.stringify({text: "#" + this.state.order._id.substring(18).toUpperCase() + " (" + this.state.order.clientName + ") Sipariş Hakkında", data: {orderId: this.state.order._id}})),'popup','width=400,height=600')
        }}><ForumIcon  htmlColor={'#3c9cd7'} /></a>
                </div>} />}
                {this.state.order.status > 0 && <DetailText editable={false} label={"Atanmış Numara"} value={this.state.order.phone} />}
                
                {this.state.order.vehicle == 6 && <>
                
                    {this.state.order.scheduledStartDate != undefined && <DetailText editable={false} label={"Kesinleş. Tarih"} value={(new Date(this.state.order.scheduledStartDate).toLocaleDateString("tr-TR")+" " + digit(new Date(this.state.order.scheduledStartDate).getHours()))+":"+digit(new Date(this.state.order.scheduledStartDate).getMinutes())+"-"+digit(new Date(this.state.order.scheduledEndDate).getHours())+":" + digit(new Date(this.state.order.scheduledEndDate).getMinutes())} />}
                
                </>}
                
                {this.state.order.code != undefined && <DetailText editable={false} label={"Teslim Kodu"} value={this.state.order.code} />}
                {this.state.order.code == undefined && this.state.order.status != 10 && this.state.order.paymentMethod == 5 && <DetailText editable={false} label={"Teslim Kodu"}  >
                <Typography style={{color:"crimson"}}>Ödeme Yapılmamış</Typography>
                    </DetailText>}
                
                <DetailText customEdit={() => <><Select onChange={(a) => {
                    this.state.order.acquisitionType = a.target.value;
                    this.forceUpdate();
                }} value={this.state.order.acquisitionType || ''}>
                    {Object.keys(AcqusitionTypes).map((val, indx) => <MenuItem key={indx} value={val} >{AcqusitionTypes[val]}</MenuItem>)}
                </Select>
                
                {this.state.order.acquisitionType == -1 && <TextField onChange={(e) => {
                    this.state.order.acquisitionNote = e.target.value;
                    this.forceUpdate();
                }} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={this.state.order.acquisitionNote || ""} />}
                {/* style={{position:"absolute",right:24, left:"20%", padding:0}}  */}
                </>} label={"Nereden Duydu?"} value={this.state.order.acquisitionType?(AcqusitionTypes[this.state.order.acquisitionType] + (this.state.order.acquisitionType == -1?" " +(this.state.order.acquisitionNote || ""):"")):""} />
                </div>

                {this.state.order.status > 1 && this.state.order.status != 3 && <DetailText editable={false} label={"Geçen Servis Süresi"} value={timePassed} />}
            
            </Badge>

            
            {this.state.order.invoiceDetails != undefined && <Badge style={{marginTop:24,padding:0}}>
                <div style={{display:"flex",justifyContent:"space-between", padding:24}}>
            <Typography style={{}} variant='h6'>Fatura Talebi</Typography>

            <div style={{display:"flex",alignItems:"center",gap: 10}}>
            {this.state.order.invoiceDetails?.status == 10 && <div style={{color:"white", backgroundColor:"green", fontWeight:"bold",padding:5,paddingLeft:12, paddingRight:12, borderRadius:100}}><Typography fontWeight={"bold"}>Fatura Onaylandı</Typography></div>}
                {this.state.order.invoiceDetails.invoiceUrl == undefined && <div style={{color:"white", backgroundColor:"crimson", fontWeight:"bold",padding:5,paddingLeft:12, paddingRight:12, borderRadius:100}}><Typography fontWeight={"bold"}>Fatura Yüklenmedi</Typography></div>}
                {this.state.order.invoiceDetails.invoiceUrl != undefined && this.state.order.invoiceDetails.status != 10 && <div style={{color:"white", backgroundColor:"crimson", fontWeight:"bold",padding:5,paddingLeft:12, paddingRight:12, borderRadius:100}}><Typography fontWeight={"bold"}>ONAY BEKLIYOR</Typography></div>}
                {this.state.order.invoiceDetails?.status == 1 && <Button variant='contained'  component="label" color='success' onClick={() => {
                     global.openPrompt("Faturayı onaylamak istediğinize emin misiniz?","Bu işlem onaylandıktan sonra yüklenmiş olan fatura müşteriye iletilecek (Taşımacım'a değil ise) ve Cari ise sürücüye bakiyesi otomatik olarak yatırılacak","Onayla", () => {
                        global.showLoadingPanel();
                        API.fetch("approveinvoice", {id: this.state.order._id}).then(e => {
                            location.reload();
                        })
                    })
                }}>Onayla</Button>}

                {this.state.order.invoiceDetails?.status != 10 && <Button variant='contained'  component="label">Fatura Yükle
                <input
    type="file"
    hidden
    onChange={async e => {
        global.showLoadingPanel();
        const ff = e.target.files[0]

       
        let formData = new FormData()
        formData.append('file', ff);
        formData.append('id', this.state.order._id);

        API.sendFile("uploadinvoice", formData).then(res => {
            if(res.error)
                alert(res.error);
            else {
                location.reload();
            }
        })
        
        // API.fetch('uploadinvoice', {file: ff.name,id: this.state.order._id}).then(async e => {
            
        //     if(!e.url) return;

        //     let formData = new FormData()
        //     formData.append('file', ff);
            
            
        //     const response = await fetch(e.url, {method: "PUT",body:ff,headers: {
        //             "Content-Type":"multipart/form-data"
        //     }});

            

            
           
        //     //console.log(response);
        //    location.reload();
        //     //global.hideLoadingPanel();
        // })
        
    }}
  />
                </Button>}
            </div>
            </div>

            {this.state.order.invoiceDetails.invoiceUrl != undefined && <InvoicePreview url={this.state.order.invoiceDetails.invoiceUrl} />}

            
            <div style={{paddingLeft:24, paddingBottom:24}}>



                <Typography>
                {this.state.order.invoiceDetails.toTasimacim == true && <>
                    Kurumsal Detaylar:<br/>
                    TAŞIMACIM YAZILIM TEKNOLOJİLERİ ANONİM ŞİRKETİ<br/>
                    KAVACIK MAH. FATİH SULTAN MEHMET CAD. TONOGLU NO: 3 İÇ KAPI NO: 4 BEYKOZ/ İSTANBUL<br/>
                    Vergi Dairesi: Beykoz<br/>
                    VKN: 8281080316<br/>
                </>}
                
                {this.state.order.invoiceDetails.toTasimacim != true && this.state.order.invoiceDetails.isBusiness == true && <>
                    Kurumsal Detaylar:<br/>
                    {this.state.order.invoiceDetails.businessName}<br/>
                    {this.state.order.invoiceDetails.address}<br/>
                    Vergi Dairesi: {this.state.order.invoiceDetails.businessTaxDep}<br/>
                    VKN: {this.state.order.invoiceDetails.businessTaxNo}<br/>
                    </>}

                    {this.state.order.invoiceDetails.toTasimacim != true && this.state.order.invoiceDetails.isBusiness == false && <>
                    Bireysel Detaylar:<br/>
                    {this.state.order.invoiceDetails.individualName} {this.state.order.invoiceDetails.individualSurname}<br/>
                    {this.state.order.invoiceDetails.address}<br/>
                    T.C. Kimlik Numarası: {this.state.order.invoiceDetails.individualID}<br/>
                    </>}

                KDV Dahil Tutar: {(this.state.order.pricing.price +  ((this.state.order.pricing.bonus)?parseFloat(this.state.order.pricing.bonus):0)).toFixed(2)}₺
                </Typography>
            </div>


            </Badge>}

            {this.state.order.vehicle == 6 && <>
                <Typography style={{marginTop:24}} variant='h6'>Müsaitlik Takvimi</Typography>
                {this.state.order.kioskId  == undefined && <TimetableComponent onTimesChanged={(dates) => {
                    if(this.state.editing) {
                        this.state.order.times = dates;
                        this.forceUpdate();
                    }
                }} timeStartDate={this.state.order.timeStartDate} selectedDates={this.state.order.times} />}
            
                {this.state.order.kioskId  != undefined && <JYSKTimetableComponent onTimesChanged={(dates) => {
                    if(this.state.editing) {
                        this.state.order.times = dates;
                        this.forceUpdate();
                    }
                }} timeStartDate={this.state.order.timeStartDate} selectedDates={this.state.order.times} />}
            </>}

            {this.state.order.items != undefined && <>
                <Typography style={{marginTop:24, marginBottom:10}} variant='h6'>Ürün Listesi {"("+this.state.order.items.length+")"}</Typography>
                <div style={{display:"flex",flexDirection:"column", gap:10}}>
                {this.state.order.items.map((x, i) => <ItemDescription item={x} key={i} />)}
                </div>

            </>}

            {this.state.order.review != undefined && <Review review={this.state.order.review} />}


           
                
                <div style={{height:350, marginTop:30, borderRadius:8, overflow:"hidden"}}>
            <MapContainer ref={(a) => {if(this.mapContainer == undefined && a != undefined) {this.mapContainer = a; this.mapContainer.fitBounds(this.mapBounds, {padding: [100,100], animate: false})}}}  center={[41.07011330219962, 29.025937852318005]} style={{width:"100%",height:"100%"}} zoom={10.5} scrollWheelZoom={true}>
                <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
                    {/* {Towns.map((x, i) => <Marker key={i} position={[x.geolocation.lat, x.geolocation.lon]}>
    <Popup>
      {x.name}
    </Popup>
  </Marker>)} */}
{this.state.order.locations != undefined && this.state.order.locations.length > 0 && <Marker position={[this.state.order.locations[this.state.order.locations.length-1].l[1],this.state.order.locations[this.state.order.locations.length-1].l[0]]} />}
{this.state.order.status != 2 && this.state.order.status != 10 && this.state.order.status != -1 && this.state.order.driverLastLocation && <Marker position={[this.state.order.driverLastLocation.coordinates[1],this.state.order.driverLastLocation.coordinates[0]]} />}
  <Marker icon={greenIcon} opacity={this.state.order.currentWaypoint == 0?1:.5} position={[this.state.order.from.coordinates[1],this.state.order.from.coordinates[0]]}>
  <Popup>
      Konum #0
    </Popup>
  </Marker>

  <Marker icon={greenIcon} opacity={this.state.order.currentWaypoint == 1?1:.5} position={[this.state.order.to.coordinates[1],this.state.order.to.coordinates[0]]}>
    
    
  <Popup>
      Konum #1
    </Popup>    
</Marker>

{this.polyLine != undefined && <Polyline  positions={this.polyLine}/>}


  
  {/* {this.state.order.waypoints.map((x, i) => <Marker key={i} position={[x.loc.coordinates[1], x.loc.coordinates[0]]}>
    <Popup>
      {x.name} • {x.vehicles.map((x) => types[x.type]).join(",")} • {(x.credits+x.freeCredits).toFixed(2)}₺
      <br/>
      {new Date(x.loc.time).toLocaleString()}
      {console.log(x)}
    <br></br>
      <a href={'../driver/'+x._id}>{x._id}</a>
    </Popup>
  </Marker>)} */}

  
                </MapContainer>

                </div>

                {lastLocationUpdateDate != undefined && <p style={{textAlign:"center", marginTop:2}}>Sürücü lokasyonu son güncelleme: {lastLocationUpdateDate.toLocaleString('tr-TR')}</p>}
          


            <Badge style={{marginTop:24, padding:0, paddingBottom:24}}>
                
                <Typography  style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>Ödeme Detayları</Typography>

                <DetailText editable={false} label={"Servis Süresi"} value={(time)} />

                <DetailText editable={false} label={"Ödeme Tipi"} value={this.state.order.paymentMethod == 1?"Nakit Ödeme":this.state.order.paymentMethod==5?"Kapıda Online Kredi Kartı":this.state.order.paymentMethod==6?"Cari Hesap":"Kredi Kartı"} />
                
                <DetailText editable={false} label={"Baz Fiyat"} value={baseFee.toFixed(2)} />
                {this.state.order.pricing.bridges.map((x,i) => <DetailText editable={false} label={"Köprü Geçiş #"+x.id} key={i} value={x.price.toFixed(2)} />)}
                {this.state.order.additionals.map((x, i) => {

                    if(!x) return <></>

                    return <DetailText editable={false} label={ADDITIONALS[i] + (typeof x == 'number'?" x" + x:"")} key={i} value={this.state.order.additionalPrices[i] * .85} />
                })}
                {this.state.order.pricing.bonus && <DetailText editable={false} label={"Bonus"} value={this.state.order.pricing.bonus.toFixed(2)} />}
                {this.state.order.coupon && <DetailText editable={false} label={"Kupon"} value={"-"+this.state.order.coupon.discount.toFixed(2)} />}
                <DetailText editable={false} label={"Komisyon"} value={this.state.order.pricing.commision.toFixed(2)} />
                <DetailText editable={false} label={"Toplam Ödeme"} value={(this.state.order.pricing.price +  ((this.state.order.pricing.bonus)?parseFloat(this.state.order.pricing.bonus):0)).toFixed(2)} />
                <DetailText editable={false} label={"Sürücüye Geçecek"} value={((this.state.order.pricing.price +  ((this.state.order.pricing.bonus)?parseFloat(this.state.order.pricing.bonus):0))-this.state.order.pricing.commision).toFixed(2)} />
            </Badge>


            <Badge style={{marginTop:24, padding:0, paddingBottom:24, paddingRight:24}}>
                
                <Typography style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>Son İşlemler</Typography>
               
                {this.state.order.history.map((x, i) => <OrderLogs key={i} value={(() => {
                    
                    var label = {label: "", link: "", linkname: ""};
                    
                    for(var i = 0; i < labelChecks.length; i++) {
                        var s = labelChecks[i](x,this.state.order);
                        if(s) {
                            label = s;
                            break;
                        }
                    }
                    
                    label = {...label, date: x.d};
                    return label;


                })()} />)}
                
                {/* <DataGrid
                
                disableSelectionOnClick={true}
                autoHeight
                rows={this.state.order.history.map((x, i) => {return {id: i, "AD SOYAD": x, "TARİH": dateFromObjectId(x._id).toLocaleString("tr-TR"), "DURUM": x.status, "TELEFON": x.tel, "ARAÇ": NAMES[x.type]}})}
                columns={[{field: "AD SOYAD",flex:1, renderCell: (params) => {
                    
                    return <div style={{display:"flex",alignItems:"center"}}>
                        <Avatar src={""+params.value.profileURL+""}  />
                        
                        <div style={{marginLeft:15}}>
                            <Typography variant="body1">{params.value.firstName + " " + params.value.lastName}</Typography>
                            <Typography variant="body2">{params.value.email}</Typography>
                        </div>
                    </div>
                }}, {field: "TARİH",flex:1} , {field: "DURUM",flex:1, renderCell: (params) => {
                    
                    return <div style={{paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, backgroundColor:(params.value ==0)?"orange":"green", borderRadius:20, color:"white", fontWeight:"600"}}>{params.value == 0?"BAŞVURU YAPILIYOR ("+params.value+")":"ONAY BEKLİYOR (1)"}</div>
                }}, {field: "TELEFON",flex:1},{field:"ARAÇ", flex:1},{field: " ", renderCell: (params) => {
                    return <Link to={'/application/' + params.row['AD SOYAD']._id} ><Button><ArrowForwardIcon /></Button></Link>
                }}]}
                pageSize={50}
                rowsPerPageOptions={[50]}
                
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            /> */}
            </Badge>

            <div style={{display:"flex",flex:1, justifyContent:"flex-end",marginTop:10}}>
                <Button onClick={() => {
                    this.setState({addHistoryUpdateModalOpen: true, addHistoryUpdateText: ""})
                }} variant='contained'>Gelişme Ekle</Button>
            </div>

            {/* {this.state.driver.vehicles && this.state.driver.vehicles.map((vehicle, i) => <Badge style={{marginTop:24, padding:0, paddingBottom:24}} key={i}>
                
                <Typography style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>ARAÇ #{i}</Typography>

                <DetailText onChange={(e) => {vehicle.plate = e; this.forceUpdate()}} first label={"Plaka"} value={vehicle.plate} />
                <DetailText customEdit={() => <Select onChange={(e) => {vehicle.type = e.target.value; this.forceUpdate()}} value={vehicle.type} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}} style={{position:"absolute", left:"20%"}}>
                    <MenuItem value={0}>Motorsiklet</MenuItem>
                    <MenuItem value={2}>Doblo</MenuItem>
                    <MenuItem value={3}>Minivan</MenuItem>
                    <MenuItem value={4}>Panelvan</MenuItem>
                    <MenuItem value={5}>Kamyonet</MenuItem>
                    
                </Select>} label={"Araç Tipi"} value={VEHICLE_NAMES[vehicle.type]} />
                
                
            </Badge>)} */}


            {/* <Badge style={{marginTop:24,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Son Siparişler</Typography>
                
                </div>
            <DataGrid
                autoHeight
                
                rows={this.state.driver.orders.map((x,i) => ({id:i,order:x,Tarih: dateFromObjectId(x._id).toLocaleString("tr-TR"),Araç: VEHICLE_NAMES[x.vehicle],Durum: (x.status == -1 && x.driver == this.state.driver._id)?11:(x.driver == this.state.driver._id)?x.status:12,ID: x._id.substring(0,7).toUpperCase()}))}
                columns={[{field: "ID",flex: 1}, {field: "Müşteri",flex: 1,renderCell: (params) => <RouterLink to={'/client/'+params.row.order.clientId}><Link>{params.row.order.clientId}</Link></RouterLink>},{field: "Tarih",flex: 1},{field:"Araç", flex:1}, {field: "Durum",flex: 1, renderCell: (params) => <div style={{paddingTop:5, paddingBottom:5, paddingLeft:10, paddingRight:10,borderRadius:20, backgroundColor:STATUS[params.value].color}}>
                    <Typography sx={{color:"white",fontWeight:"700"}} variant='body2'>{STATUS[params.value].t}</Typography>
                </div>}]}
                pageSize={10}
                rowsPerPageOptions={[10]}
                
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />
            </Badge> */}

            


{IN_EDIT_MODE && <div style={{display:"flex", justifyContent:"flex-end", marginTop:24, alignItems:"center"}}>
               
                {!this.state.saving && <Button onClick={() => {
                    this.setState({saving: true});

                    
                    API.fetch("saveorder", this.state.order).then((e) => {
                        this.setState({saving: false, editing: false});
                    })
                }} variant='contained' color='success' size='medium'>Kaydet</Button>}
                {this.state.saving && <CircularProgress />}
            </div>}

            
        </div>

        
        <Dialog
        open={this.state.assignDriverModalOpen}
        onClose={() => {
            this.setState({assignDriverModalOpen: false});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Sürücü Ata
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{width:"80vw", maxWidth:400}}>
          {this.state.selectedDriverToAssign == undefined && <><Typography style={{marginBottom:10}}>Sürücü</Typography>
        <SearchBarComponent onValueSelected={(v) => {
            if(!v) return;
            
            this.setState({selectedDriverToAssign: v});
        }} search={{drivers: true}} /></>}
        {this.state.selectedDriverToAssign != undefined && <div style={{display:"flex",width:"100%", gap:10, alignItems:"center"}}>
        
            <Typography>{this.state.selectedDriverToAssign.label}</Typography>
            <Button onClick={() => this.setState({selectedDriverToAssign: undefined})}>X</Button>
        </div>}

        </div>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => {
            this.setState({assignDriverModalOpen: false});
            
          }}>
            İptal
          </Button>
          
          <Button disabled={this.state.selectedDriverToAssign==undefined} variant='contained' onClick={() => {

            global.showLoadingPanel();

            API.fetch('assigndriver', {_id: this.state.order._id, driver: this.state.selectedDriverToAssign._id}).then(e => {
                global.hideLoadingPanel();
                if(e.error) {
                    global.showPopup("Hata", e.error);
                } else {
                    window.location.reload();
                }
            })
            this.setState({assignDriverModalOpen: false});
            
          }}>
            Ata
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={this.state.addHistoryUpdateModalOpen}
        onClose={() => {
            this.setState({addHistoryUpdateModalOpen: false});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Sipariş Gelişmesi Ekle
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{width:"85vw", maxWidth:400}}>

            <TextareaAutosize autoFocus placeholder='Gelişme hakkında not... (Gizli)' style={{border:"1px solid blue",flex:1,width:"100%", padding:10}}  value={this.state.addHistoryUpdateText} onChange={(e) => {this.setState({addHistoryUpdateText: e.currentTarget.value})}}  minRows={3}  />

        </div>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => {
            this.setState({addHistoryUpdateModalOpen: false});
            
          }}>
            İptal
          </Button>
          
          <Button disabled={!this.state.addHistoryUpdateText} variant='contained' onClick={() => {
            this.setState({addHistoryUpdateModalOpen: false});
            global.showLoadingPanel();

            API.fetch('addworkerorderupdate', {_id: this.state.order._id, text: this.state.addHistoryUpdateText}).then(e => {
                
                if(e.error) {
                    global.hideLoadingPanel();
                    global.showPopup("Hata", e.error);
                    this.setState({addHistoryUpdateModalOpen: true});
                } else {
                    window.location.reload();
                }
            })
            
            
          }}>
            Ekle
          </Button>
        </DialogActions>
      </Dialog>
        

      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.backdropVisible}
  
>
  <CircularProgress color="inherit" />
</Backdrop>
        </div>
    }
}
const Waypoint = (props) => {

   return <>
        <DetailText editable={false} label={"Konum #" + props.title} value={API.getAddressLabel(props.waypoint)} />
        {(props.waypoint.contactName || props.waypoint.contactNO) && <DetailText editable={false} label={"^ İrtibat"} value={(props.waypoint.contactName || "") + (props.waypoint.contactName && props.waypoint.contactNO?" · ":"") + props.waypoint.contactNO} />}
    </>
}

const LogisticAnalytic = (props) => {
    return <Badge style={{marginLeft: props.first?0:24}}>
        <div style={{display:"flex", alignItems:"center"}}>
            <div style={{backgroundColor: props.backgroundColor, width:40, height:40, borderRadius:8, justifyContent:"center", alignItems:"center", display:"flex"}}>
                {props.icon}
            </div>

            <Typography style={{marginLeft:10}} variant='h5'>{props.value}</Typography>
        </div>
        <Typography style={{marginTop:15,color:"rgb(101, 116, 139)",fontWeight:"400"}} variant='body2'>{props.title}</Typography>
    </Badge>
}

const InvoicePreview = ({url}) => {

    var extension = url.split(".").pop().toLowerCase();
    console.log(url);
    return <div>

        <div style={{display:"flex", justifyContent:"center"}}>
        
        {(extension.endsWith("png") || extension.endsWith("jpeg") || extension.endsWith("jpg")) && <img src={url} />}
        {(extension.endsWith("pdf")) && <Document onDocumentLoad={(e) => console.log(e)} onLoadError={(error) => console.log("Inside Error", error)} file={{url: url}}>
        <Page width={window.innerWidth*.5} pageNumber={1} />
        {/* <Page pageNumber={pageNumber} /> */}
      </Document>}

        </div> 
    </div>
}


const FilePreview = ({file, urlBase,title, ...rest}) => {
    return <div>

        {!IN_EDIT_MODE && <Typography style={{marginBottom:24, marginLeft:24, marginTop:24,textAlign:"center"}} variant='h6'>{title}</Typography>}
        {IN_EDIT_MODE && <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <Typography style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>{title}</Typography>

        {file && !file.removed && <div style={{marginLeft:10}}>
            <Button onClick={() => {
                rest.onClick();
            }} variant='outlined' color='error'>Sil</Button>
        </div>}
        </div>}
        <div style={{display:"flex", justifyContent:"center"}}>
        
        {file && !file.removed && (file.path.endsWith(".png") || file.path.endsWith(".jpeg") || file.path.endsWith(".jpg")) && <img {...rest}  src={urlBase+file.path} />}
        {file && !file.removed && (file.path.endsWith(".pdf")) && <Document file={{url: urlBase+file.path}}>
        {/* <Page pageNumber={pageNumber} /> */}
      </Document>}
        
        {file && file.removed && <Typography variant='h6' color='crimson'>KALDIRILDI</Typography>}
        </div> 
    </div>
}

const DetailText = (props) => {
    return <div  style={{position:"relative",flexDirection:"column",display:"flex",paddingLeft:24, paddingTop:10, paddingBottom:10, borderBottom:"solid 1px #E6E8F0", borderTop:(props.first?"solid 1px #E6E8F0":"")}}>
        
<div style={{display:"flex",flex:1}}>
        <div style={{maxWidth:180,flex:.6, display:"flex", alignItems:(props.centerLabel)?"center":undefined}}>
        <Typography style={{fontWeight:"600"}}>{props.label}</Typography>
        </div>
        <div className='detailParent' style={{flex:1, alignItems:"center", justifyContent:"center"}}>
        {(!IN_EDIT_MODE || props.editable == false) && props.link == undefined && <Typography {...props}>{props.value}</Typography>}
        {(!IN_EDIT_MODE || props.editable == false) && props.link != undefined && <Link to={props.link} ><Typography {...props}>{props.value}</Typography></Link>}
        {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)}  sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
        {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()}
        {!IN_EDIT_MODE && props.children && {...props.children}}
        </div>
        {/* 


        {(!IN_EDIT_MODE || props.editable == false) && <Typography {...props} style={{position:"absolute", left:window.innerWidth < 500?"40%":"20%"}}>{props.value}</Typography>}
        {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)} style={{position:"absolute",right:24, left:"20%", padding:0}} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
        {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()} */}

</div>

        {props.altComponent != undefined && props.altComponent}
    </div>
}

// const DetailText = (props) => {
//     return <div  style={{position:"relative",display:"flex", paddingTop:10, paddingBottom:10,alignItems:"center", borderBottom:"solid 1px #E6E8F0", borderTop:(props.first?"solid 1px #E6E8F0":"")}}>
        

//         <Typography style={{fontWeight:"600", marginLeft:24}}>{props.label}</Typography>


//         {(!IN_EDIT_MODE || props.editable == false) && <Typography {...props} style={{position:"absolute", left:window.innerWidth < 500?"40%":"20%"}}>{props.value}</Typography>}
//         {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)} style={{position:"absolute",right:24, left:"20%", padding:0}} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
//         {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()}
//     </div>
// }

const OrderLogs = (props) => {
   
    return <><DetailText altComponent={props.value.altComponent} label={props.value.label} editable={false}>
        <div style={{display:"flex",flexDirection:"row"}}>
{(props.value.linkname == undefined || props.value.linkname.startsWith(".")) && <Typography><Link to={props.value.link}>{props.value.linkname}</Link>{props.value.value} {new Date(props.value.date).toLocaleString("tr-TR")}</Typography>}
        
        {props.value.customElement == undefined && (props.value.linkname != undefined && !props.value.linkname.startsWith(".")) && <Typography><a target={"_blank"} href={props.value.link}>{props.value.linkname}</a>{props.value.value} {props.value.p?<a href="#" onClick={() => {
            window.open(props.value.p,'popup','width=400,height=600')
        }}>Mesajlari Gör </a>:<></>}{new Date(props.value.date).toLocaleString("tr-TR")}</Typography>}

        {props.value.customElement != undefined && props.value.customElement}

        {props.value.alt != undefined && props.value.alt}
        </div>
    </DetailText>

    

    </>

        
}

// const OrderLogs = (props) => {
//     return <div style={{position:"relative",display:"flex", paddingTop:10, paddingBottom:10, borderBottom:"solid 1px #E6E8F0", borderTop:(props.first?"solid 1px #E6E8F0":"")}}>
//         <Typography style={{fontWeight:"600", marginLeft:24, width: 140}}>{props.value.label}</Typography>

//         <div style={{display:"flex", flex:1, alignItems:"center"}}>
        
//         {(props.value.linkname == undefined || props.value.linkname.startsWith(".")) && <Typography><Link to={props.value.link}>{props.value.linkname}</Link>{props.value.value} {new Date(props.value.date).toLocaleString("tr-TR")}</Typography>}
        
//         {props.value.customElement == undefined && (props.value.linkname != undefined && !props.value.linkname.startsWith(".")) && <Typography><a target={"_blank"} href={props.value.link}>{props.value.linkname}</a>{props.value.value} {props.value.p?<a href="#" onClick={() => {
//             window.open(props.value.p,'popup','width=400,height=600')
//         }}>Mesajlari Gör </a>:<></>}{new Date(props.value.date).toLocaleString("tr-TR")}</Typography>}

//         {props.value.customElement != undefined && props.value.customElement}

//         {props.value.alt != undefined && props.value.alt}
//         </div>
//     </div>
// }


const DetailLinkedText = (props) => {
    return <div style={{position:"relative",display:"flex", paddingTop:10, paddingBottom:10,alignItems:"center", borderBottom:"solid 1px #E6E8F0", borderTop:(props.first?"solid 1px #E6E8F0":"")}}>
        <Typography style={{fontWeight:"600", marginLeft:24}}>{props.label}</Typography>


        {(!IN_EDIT_MODE || props.editable == false) && <Link to={props.link}><Typography style={{position:"absolute", left:"20%"}}>{props.value}</Typography></Link>}
        {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)} style={{position:"absolute",right:24, left:"20%", padding:0}} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
        {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()}
    </div>
}
var dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}
function convertMillisToTimeString(millis) {
    const hours = Math.floor(millis / (1000 * 60 * 60));
    const minutes = Math.floor((millis % (1000 * 60 * 60)) / (1000 * 60));

    let timeString = '';

    if (hours > 0) {
        timeString += `${hours} saat`;
    }

    if (minutes > 0) {
        if (timeString) timeString += ' ';
        timeString += `${minutes} dakika`;
    }

    return timeString || '0 dakika';
}


function secondsToString(seconds) {
    // Calculate minutes and remaining seconds
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
  
    // Format the output string
    const formattedString = ((minutes > 0)?`${minutes}dakika `:'')+(remainingSeconds > 0?`${remainingSeconds}saniye`:'');

    if(seconds <= 0) return "0sn";
  
    return formattedString;
  }

  function digit(num) {
    return `${num}`.padStart(2, '0');
}
  