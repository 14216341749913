import React from 'react';
import { Typography, Button, Avatar, Menu,TextField, Select, MenuItem,CircularProgress, Backdrop, Link, Tooltip, TextareaAutosize, colors, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import API from '../API';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink} from 'react-router-dom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ErrorIcon from '@mui/icons-material/Error';
import { Document } from 'react-pdf';
import Rating from '@mui/material/Rating';
import WhatsappIcon from '../whatsapp.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Image } from '@mui/icons-material';
import Review from '../components/Review';
import ForumIcon from '@mui/icons-material/Forum';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OrderIDTableCell from '../components/OrderIDTableCell';
// const FILES = ["Sürücü Belgesi Ön", "Sürücü Belgesi Arka", "İkametgah Belgesi", "Adli Sicil Kaydı", "Profil Fotoğrafı"];
const VEHICLE_NAMES = ["Motorsiklet", "Sedan", "Doblo", "Minivan", "Panelvan", "Kamyonet", "Esnek"];

const fileNames = ['Sürücü Belgesi Ön',"Sürücü Belgesi Arka", "İkametgah Belgesi","Adli Sicil Kaydı","Profil Fotoğrafı",'Vergi Levhası'];
const optionalFileNames = ['K Sertifikası', 'Psikoteknik Sertifikası', 'SRC Sertifikası']

var IN_EDIT_MODE = false;

var STATUS = {};
STATUS[1] = {
    t: "Planlanmış",
    color: "rgb(80,72,229)"
}
STATUS[2] = {
    t: "Aktif Sipariş",
    color: "orange"
}
STATUS[3] = {
    t: "Tarih Kesinleş.",
    color: "rgb(80,72,229)"
}
STATUS[10] = {
    t: "Tamamlanmış",
    color: "green"
}
STATUS[11] = {
    t: "Müşteri İptal",
    color: "yellow"
}
STATUS[-1] = STATUS[11];
STATUS[12] = {
    t: "Sürücü İptali",
    color:"crimson"
}
export default class DriverScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: true,editing: false, saving: false, approveDriverDialog: false, backdropVisible: false, vehiclePromptVisible: false, vehiclePromptFile: undefined, vehiclePromptPlate: "", vehiclePromptType: 0};

        
        var id = props.match.params.id;

        
        if(!id) props.history.goBack();

        
    }

    componentDidMount() {
        var id = this.props.match.params.id;
        
        API.fetch("getdriver", {_id: id}).then((e) => {
           
            if(e.error) return this.props.history.goBack();
            else {
                var c = 0;
                e.orders.forEach((order) => {
                    if(order.driver != e._id && order.status != -1) c++;
                })

                e.canceledOrderCount = c;

                if(e.invoiceDetails == undefined) e.invoiceDetails = {};
            }


            this.setState({driver: e, loading: false});
        })
        // API.fetch('getapplications').then((e) => {
        //     if(!e.error) this.setState({applications: e});
        // })
    }
    render() {
        IN_EDIT_MODE = this.state.editing;


        if(this.state.loading) return <></>
        return <div style={{display:"flex", justifyContent:"center"}}>

            <div style={{maxWidth:900, width:"100%"}}>
            <div style={{marginTop:40, display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <div style={{display:"flex"}}>
                    <Avatar sx={{width:70, height:70}} src={"https://ik.imagekit.io/tasimacim/driver/tr:w-auto/"+this.state.driver._id+".jpeg"} /> {/*https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/*/}
                    <div style={{marginLeft:20}}>
                        
                        <div style={{display:"flex", alignItems:"center", gap: 10}}><Typography variant='h4'>{this.state.driver.name}</Typography><Tooltip title={this.state.driver.reviews.length + " değerlendirmede " + (this.state.driver.review || 0).toFixed(2) + " yıldız"}><div style={{display:"flex", alignItems:"center"}}><Rating precision={0.01} readOnly  value={this.state.driver.review} /><Typography variant='body1'>({this.state.driver.reviews.length})</Typography></div></Tooltip></div>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <Typography variant='body2'>partner_id: </Typography>
                            <div style={{marginLeft:10, backgroundColor:"#E5E7EB", borderRadius:50, paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2}}>
                                <Typography style={{fontWeight:"600"}} variant='body2'>{this.state.driver._id}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                <div style={{display:"flex",marginBottom:5,justifyContent:"space-evenly",alignItems:"center"}}>
                <a href="#" style={{fill: "#3c9cd7"}} onClick={() => {
            window.open('../livesupport/'+this.state.driver._id,'popup','width=400,height=600')
        }}><ForumIcon  htmlColor={'#3c9cd7'} /></a>
                <a href={"https://wa.me/"+(this.state.driver.phone)} target={'_blank'}><img style={{width:25,height:25,marginRight:18}} src={WhatsappIcon} /></a>
                </div>
                    {!this.state.editing && <Button onClick={() => {

                        this.driverOld = JSON.parse(JSON.stringify(this.state.driver));
                        this.setState({editing: true});
                    }} style={{marginRight:20}} variant='outlined'>Düzenle <EditIcon sx={{width:18, height:18}} style={{marginLeft:10}} /></Button>}
                    
                    
                    {this.state.editing && <Button onClick={() => {

                        
                        this.setState({editing: false, driver: this.driverOld});
                    }} style={{marginRight:20}} color='error' variant='outlined' >İptal <CloseIcon sx={{width:18, height:18}} style={{marginLeft:10}} /></Button>}
                    {/* {!this.state.editing && <Button onClick={() => this.setState({approveDriverDialog: true})} variant='contained'>Başvuruyu Onayla</Button>} */}
                </div>



                
            </div>

                <div style={{display:"flex",marginTop:24}}>
                    <LogisticAnalytic backgroundColor={'rgba(80,72,229,.1)'} first title={"Son siparişler"} value={this.state.driver.orders.length} icon={<LocalShippingIcon sx={{color: 'rgb(80,72,229)'}} />} />
                    <LogisticAnalytic backgroundColor={"rgba(134,197,82,.1)"} title={"Güncel bakiye"} value={(this.state.driver.credits+this.state.driver.freeCredits).toFixed(2) +(this.state.driver.freeCredits > 0?" ("+this.state.driver.credits.toFixed(2)+")":"")} icon={<AccountBalanceWalletIcon sx={{color: 'rgb(134,197,82)'}} />} />
                    <LogisticAnalytic backgroundColor={"rgba(255,176,32,.1)"} title={"7 günde iptal"} value={(this.state.driver.canceledOrderCount)} icon={<ErrorIcon sx={{color: 'rgb(255,176,32)'}} />} />
                
                    
                </div>

<Badge style={{marginTop:24,padding:0}}>
                <div style={{padding:0}}>
                <Typography style={{marginBottom:20, marginLeft:24, marginTop:24}} variant='h6'>Detaylar</Typography>
                
                <DetailText first label={"Email"} onChange={(e) => {this.state.driver.email = e; this.forceUpdate()}} value={this.state.driver.email} />
                <DetailText label={"T.C Kimlik"} onChange={(e) => {this.state.driver.tc = e; this.forceUpdate()}} value={this.state.driver.tc} />
                <DetailText label={"VKN"} onChange={(e) => {this.state.driver.vkn = e; this.forceUpdate()}} value={this.state.driver.vkn} />
               
                <DetailText label={"Telefon"} onCopy={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(document.getSelection().toString().replace(/ /g, ''));

                }} onChange={(e) => {this.state.driver.phone = e; this.forceUpdate()}}  value={`+90 ${this.state.driver.phone.slice(3, 6)} ${this.state.driver.phone.slice(6, 9)} ${this.state.driver.phone.slice(9)}`} />
                <DetailText editable={false} label={"Kayıt Tarihi"} value={dateFromObjectId(this.state.driver._id).toLocaleString("tr-TR")} />

                <DetailText editable={false} label={"İl"} value={API.getCityName(this.state.driver.city)} />
                <DetailText label={"Not (Gizli)"} customDisplay={() => <TextareaAutosize value={this.state.driver.note} minRows={1} style={{width:"100%"}} disabled  />} value={this.state.driver.note || ""} customEdit={() => <TextareaAutosize value={this.state.driver.note} minRows={1} style={{width:"100%"}} onChange={(e) => {this.state.driver.note = e.currentTarget.value; this.forceUpdate()}}  />} />

                </div>
            
            </Badge>

            {this.state.driver.files != undefined && <div style={{backgroundColor:"#EDF7ED",display:"flex", columnGap: 20, flexWrap:"wrap", justifyContent:"left", padding:15,paddingLeft:20, paddingRight:20, borderRadius:5, marginTop:10}}>
                {console.log(this.state.driver.files)}
                {/* {fileNames.filter((s,i) => this.state.driver.files[i] == undefined).map((x, i) => <DriverFileStatus check={false} key={i} name={fileNames[x]} />)} */}
                {Object.keys(this.state.driver.files).map((docId, i) => <DriverFileStatus check={this.state.driver.files[docId] != undefined} key={i} name={(docId >= 1000)?optionalFileNames[docId-1000]:fileNames[docId]} />)}
                
            </div>}


            <Badge style={{marginTop:24,padding:0}}>
                <div style={{padding:0}}>
                <Typography style={{marginBottom:20, marginLeft:24, marginTop:24}} variant='h6'>Fatura Detayları</Typography>
                
                <DetailText customEdit={() =>  <RadioGroup
                style={{display:"flex",flexDirection:"row"}}
    aria-labelledby="demo-controlled-radio-buttons-group"
    name="controlled-radio-buttons-group"
    value={this.state.driver.invoiceDetails.type}
    onChange={(change) => {
        this.state.driver.invoiceDetails.type = change.target.value;
        this.forceUpdate();
    }}
  >
    <FormControlLabel label="Şahıs Şirketi" control={<Radio />} value={0} />
    <FormControlLabel label="Limited/Anonim Şirketi" control={<Radio />} value={1} />
  </RadioGroup>}  first label={"Şirket Tipi"} onChange={(e) => {this.state.driver.invoiceDetails.type = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.type == 0?"Şahıs Şirketi":this.state.driver.invoiceDetails.type == 1?"Limited/Anonim Şirketi":"-"} />
                <DetailText label={"Ünvan"} onChange={(e) => {this.state.driver.invoiceDetails.name = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.name || ""} />
                <DetailText label={"VKN"} onChange={(e) => {this.state.driver.invoiceDetails.vkn = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.vkn || ""} />
                <DetailText label={"Adres"} onChange={(e) => {this.state.driver.invoiceDetails.address = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.address || ""} />
                <DetailText label={"Vergi Dairesi"} onChange={(e) => {this.state.driver.invoiceDetails.department = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.department || ""} />
                <DetailText label={"IBAN Ünvan"} onChange={(e) => {this.state.driver.invoiceDetails.ibanName = e; this.forceUpdate()}} value={this.state.driver.invoiceDetails.ibanName || ""} />
                <DetailText label={"IBAN"} onChange={(e) => {this.state.driver.iban = e; this.forceUpdate()}} value={this.state.driver.iban} />


                    
                {this.state.driver.invoiceDetails?.imageURL != undefined && <div  style={{position:"relative",flexDirection:"column",display:"flex",paddingLeft:24, paddingTop:10,paddingRight:24, paddingBottom:10,maxHeight:500, borderBottom:"solid 1px #E6E8F0", borderTop:("")}}>
        
        <div style={{display:"flex",flex:1}}>
        <img style={{width:"100%", objectFit:"contain", maxHeight:480}} src={this.state.driver.invoiceDetails.imageURL} />
                
                {/* 
        
        
                {(!IN_EDIT_MODE || props.editable == false) && <Typography {...props} style={{position:"absolute", left:window.innerWidth < 500?"40%":"20%"}}>{props.value}</Typography>}
                {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)} style={{position:"absolute",right:24, left:"20%", padding:0}} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
                {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()} */}
        
        </div>
            </div>}
                </div>
            
            </Badge>

            {this.state.driver.loc != undefined && <div style={{height:200, marginTop:30, borderRadius:8, overflow:"hidden"}}>
            <MapContainer center={[this.state.driver.loc.coordinates[1],this.state.driver.loc.coordinates[0]]} style={{width:"100%",height:"100%"}} zoom={10.5} scrollWheelZoom={true}>
                <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
<Marker position={[this.state.driver.loc.coordinates[1],this.state.driver.loc.coordinates[0]]} />



  
  {/* {this.state.order.waypoints.map((x, i) => <Marker key={i} position={[x.loc.coordinates[1], x.loc.coordinates[0]]}>
    <Popup>
      {x.name} • {x.vehicles.map((x) => types[x.type]).join(",")} • {(x.credits+x.freeCredits).toFixed(2)}₺
      <br/>
      {new Date(x.loc.time).toLocaleString()}
      {console.log(x)}
    <br></br>
      <a href={'../driver/'+x._id}>{x._id}</a>
    </Popup>
  </Marker>)} */}

  
                </MapContainer>

                </div>}

                {this.state.driver.loc != undefined && <p style={{textAlign:"center", marginTop:2}}>Sürücü lokasyonu son güncelleme: {new Date(this.state.driver.loc.time).toLocaleString('tr-TR')}</p>}


            

            {this.state.driver.vehicles && this.state.driver.vehicles.map((vehicle, i) => <Badge style={{marginTop:24, padding:0, paddingBottom:24}} key={i}>
                
                <Typography style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>ARAÇ #{i}</Typography>

                {vehicle.active == false && <Typography style={{color:"crimson"}}>Bu araç deaktif edilmiştir ve sürücüde gözükmez</Typography>}

                <DetailText onChange={(e) => {vehicle.plate = e; this.forceUpdate()}} first label={"Plaka"} value={vehicle.plate} />
                <DetailText customEdit={() => <Select onChange={(e) => {vehicle.type = e.target.value; this.forceUpdate()}} value={vehicle.type} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}} style={{position:"absolute", left:"20%"}}>
                    <MenuItem value={0}>Motorsiklet</MenuItem>
                    <MenuItem value={2}>Doblo</MenuItem>
                    <MenuItem value={3}>Minivan</MenuItem>
                    <MenuItem value={4}>Panelvan</MenuItem>
                    <MenuItem value={5}>Kamyonet</MenuItem>
                    
                </Select>} label={"Araç Tipi"} value={VEHICLE_NAMES[vehicle.type]} />

                <DetailText onChange={(e) => {vehicle.innerWidth = e; this.forceUpdate()}}  label={"Genişlik (cm)"} value={vehicle.innerWidth || ""} />
                <DetailText onChange={(e) => {vehicle.innerHeight = e; this.forceUpdate()}}  label={"Yükseklik (cm)"} value={vehicle.innerHeight || ""} />
                <DetailText onChange={(e) => {vehicle.innerDepth = e; this.forceUpdate()}}  label={"Uzunluk (cm)"} value={vehicle.innerDepth || ""} />
                
                {/* <Typography style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>Ruhsat</Typography>
                {vehicle.ruhsat && <FilePreview style={{width:"50%"}} urlBase={this.state.application.urlBase} file={vehicle.ruhsat} />}

                <div style={{display:"flex", marginTop:24}}>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                <Typography style={{textAlign:"center", marginBottom:10}} variant='h6'>Araç Ön</Typography>
                {vehicle.front && <FilePreview style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.front} />}
                    </div>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                <Typography style={{textAlign:"center", marginBottom:10}} variant='h6'>Araç Yan</Typography>
                {vehicle.side && <FilePreview style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.side} />} */}


                {/* <FilePreview onClick={() => {vehicle.ruhsat = {removed: true}; this.forceUpdate()}} title={'Ruhsat'} style={{width:"50%"}} urlBase={this.state.application.urlBase} file={vehicle.ruhsat} />

                <div style={{display:"flex", marginTop:24}}>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                
                        <FilePreview onClick={() => {vehicle.front = {removed: true}; this.forceUpdate()}} title={'Araç Ön'} style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.front} />
                    </div>
                    <div style={{display:"flex",flex:1,flexDirection:"column"}}>
                
                        <FilePreview onClick={() => {vehicle.side = {removed: true}; this.forceUpdate()}} title={'Araç Yan'} style={{width:"70%"}} urlBase={this.state.application.urlBase} file={vehicle.side} />
                    </div>
                </div> */}
            </Badge>)}

            {this.state.editing && <Button onClick={() => {this.setState({vehiclePromptVisible: true})}} style={{marginTop:10}}>Araç Ekle</Button>}


            <Badge style={{marginTop:24,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Son Siparişler</Typography>
                
                </div>
            <DataGrid
                autoHeight
                
                rows={this.state.driver.orders.map((x,i) => ({id:i,order:x,Tarih: dateFromObjectId(x._id).toLocaleString("tr-TR"),Araç: VEHICLE_NAMES[x.vehicle],Durum: (x.status == -1 && x.driver == this.state.driver._id)?11:(x.driver == this.state.driver._id)?x.status:12}))}
                columns={[{field: "ID", renderCell:(params) => <OrderIDTableCell order={params.row.order} />,flex: 1}, {field: "Müşteri",flex: 1,renderCell: (params) => <RouterLink to={'/client/'+params.row.order.clientId}><Link>{params.row.order.clientId.substring(18).toUpperCase()}</Link></RouterLink>},{field: "Tarih",flex: 1},{field:"Araç", flex:1}, {field: "Durum",flex: 1, renderCell: (params) => <div style={{paddingTop:5, paddingBottom:5, paddingLeft:10, paddingRight:10,borderRadius:20,width:"fit-content",height:"fit-content", backgroundColor:STATUS[params.value].color}}>
                    <Typography sx={{color:"white",fontWeight:"700"}} variant='body2'>{STATUS[params.value].t}</Typography>
                </div>},{field: " ", renderCell: (params) => {
                    return <RouterLink to={'/order/' + params.row.order._id} ><Button><ArrowForwardIcon /></Button></RouterLink>
                }}]}
                pageSize={10}
                rowsPerPageOptions={[10]}
                
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />
            </Badge>

            {API.user.permissions.includes(0) && <Badge style={{marginTop:24,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Son İşlemler</Typography>
                
                </div>
                <DataGrid
            getRowId={(row) => row._id}
                autoHeight
                rows={this.state.driver.transactions}
                columns={[{field: "Tarih",flex:0.4, valueGetter: (params,row) => new Date(parseInt(row._id.substring(0, 8), 16) * 1000).toLocaleString("tr-TR")},{field: "Tür",flex:1, valueGetter: (params,row) => {
                    
                    return API.getTransactionDescription(row)
                }},{field: "Tutar", flex:0, valueGetter: (params,row) => row.value.toFixed(2)+ "₺"}]}
                pageSize={10}
                getCellClassName={(param) => param.row.status == -1?"cellTransactionCancelled":""}
                rowsPerPageOptions={[10]}
                initialState={{pagination: {paginationModel: {pageSize: 50, page: 0}}}}
                onPaginationModelChange={(model) => {
                  API.fetch('getdrivertransactions', {_id: this.state.driver._id, page: model.page}).then(e => {
                    this.state.driver.transactions = e;
                    this.forceUpdate();
                  })
                }}
                rowCount={this.state.driver.transactionCount}
                paginationMode='server'
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />
            </Badge>}

            {IN_EDIT_MODE && <div style={{display:"flex", justifyContent:"flex-end", marginTop:24, alignItems:"center"}}>
                
                {!this.state.saving && <Button onClick={() => {
                    this.setState({saving: true});

                    this.state.driver.vehicles.forEach(vehicle => {
                        if(vehicle.innerWidth && typeof(vehicle.innerWidth) != 'number') {
                            try {
                                vehicle.innerWidth = parseInt(vehicle.innerWidth);
                            } catch {}
                            if(isNaN(vehicle.innerWidth)) vehicle.innerWidth = undefined;
                        }

                        if(vehicle.innerHeight && typeof(vehicle.innerHeight) != 'number') {
                            try {
                                vehicle.innerHeight = parseInt(vehicle.innerHeight);
                            } catch {}
                            if(isNaN(vehicle.innerHeight)) vehicle.innerHeight = undefined;
                        }

                        if(vehicle.innerDepth && typeof(vehicle.innerDepth) != 'number') {
                            try {
                                vehicle.innerDepth = parseInt(vehicle.innerDepth);
                            } catch {}
                            if(isNaN(vehicle.innerDepth)) vehicle.innerDepth = undefined;
                        }
                    })

                    
                   
                    API.fetch("savedriver", this.state.driver).then((e) => {
                        this.setState({saving: false, editing: false});
                    })
                }} variant='contained' color='success' size='medium'>Kaydet</Button>}
                {this.state.saving && <CircularProgress />}
            </div>}

            {this.state.driver.reviews.filter(s => s.review.message != undefined && s.review.message != "").map((x, i) => <Review key={i} review={{_id: x._id,...x.review}} />)}

    
            <div style={{marginTop:20}}></div>
            <a target='_blank' style={{color: '#267FCA', marginTop:50}} href={"https://eu-central-1.console.aws.amazon.com/s3/buckets/tasimacim-partners?region=eu-central-1&bucketType=general&prefix="+this.state.driver._id+"/&showversions=false"}>AWS'ye Git <OpenInNewIcon /></a>




            
        </div>



        <Dialog
        open={this.state.vehiclePromptVisible}
        onClose={() => {
            this.setState({prompt: undefined});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Yeni araç kaydet
        </DialogTitle>
        <DialogContent style={{gap: 10, display:"flex", flexDirection:"column"}}>
          

          <div>

          <TextField value={this.state.vehiclePromptPlate} onChange={(e) => this.setState({vehiclePromptPlate: e.currentTarget.value})} label="Plaka" />
           
          </div>
           <Select onChange={(e) => {this.setState({vehiclePromptType: e.target.value})}} value={this.state.vehiclePromptType} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}>
                    <MenuItem value={0}>Motorsiklet</MenuItem>
                    <MenuItem value={2}>Doblo</MenuItem>
                    <MenuItem value={3}>Minivan</MenuItem>
                    <MenuItem value={4}>Panelvan</MenuItem>
                    <MenuItem value={5}>Kamyonet</MenuItem>
                    
                </Select>
                {this.state.vehiclePromptFile != undefined && <img style={{width:250, height:250, objectFit:"contain"}}  src={URL.createObjectURL(this.state.vehiclePromptFile)} />}
                <Button component="label" variant="contained">

                    
  {this.state.vehiclePromptFile != undefined?"Başka bir ruhsat yükle":"Ruhsat yükle"}
  <input onChange={(e) => {
    this.setState({vehiclePromptFile: e.target.files[0]})
    console.log(e.target.files);
  }} style={{display: "none"}} multiple={false} accept="image/png, image/gif, image/jpeg"  type="file" />
</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({vehiclePromptVisible: false})}>İptal</Button>
          <Button onClick={() => {
        
        global.showLoadingPanel();
        this.setState({vehiclePromptVisible: false})

            if(this.state.vehiclePromptFile == undefined) return;

        
            API.fetch('addvehicle', {file: this.state.vehiclePromptFile.name,driver: this.state.driver._id, type: this.state.vehiclePromptType, plate: this.state.vehiclePromptPlate}).then(async e => {
                
                if(e.error) return;

                let formData = new FormData()
                formData.append('file', this.state.vehiclePromptFile);
                
                
                const response = await fetch(e.v, {method: "PUT",body:this.state.vehiclePromptFile,headers: {
                        "Content-Type":"multipart/form-data"
                }});

                

                
            
                
                //global.hideLoadingPanel();

                //console.log(response);
                location.reload();
            })

          }} autoFocus>
            Ekle
          </Button>
        </DialogActions>
      </Dialog>
        

        

      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.backdropVisible}
  
>
  <CircularProgress color="inherit" />
</Backdrop>
        </div>
    }
}

const DriverFileStatus = (props) => 
    <div style={{display:"flex",alignItems:"center", gap: 5,width:200}}> 
    {props.check == true && <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={15}
    height={15}
    viewBox="0 0 17.837 17.837"
    {...props}
  >
    <path
      d="M16.145 2.571a.7.7 0 0 0-.99 0L6.92 10.804l-4.241-4.27a.698.698 0 0 0-.989 0L.204 8.019a.703.703 0 0 0 0 .99l6.217 6.258a.704.704 0 0 0 .99 0L17.63 5.047a.7.7 0 0 0 0-.994l-1.485-1.482z"
      style={{
        fill: "rgb(30, 70, 32)",
      }}
    />
  </svg>}
  {props.check == false && <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 24 24' width={15} height={15} {...props}>
    <path fill='crimson' d="m24 20.188-8.315-8.209 8.2-8.282L20.188 0l-8.212 8.318L3.666.115 0 3.781l8.321 8.24-8.206 8.313L3.781 24l8.237-8.318 8.285 8.203z" />
  </svg>}
  <p style={{color:props.check?"rgb(30, 70, 32)":'crimson',fontWeight:"500"}}>{props.name}</p></div>



const LogisticAnalytic = (props) => {
    return <Badge style={{marginLeft: props.first?0:24}}>
        <div style={{display:"flex", alignItems:"center"}}>
            <div style={{backgroundColor: props.backgroundColor, width:40, height:40, borderRadius:8, justifyContent:"center", alignItems:"center", display:"flex"}}>
                {props.icon}
            </div>

            <Typography style={{marginLeft:10}} variant='h5'>{props.value}</Typography>
        </div>
        <Typography style={{marginTop:15,color:"rgb(101, 116, 139)",fontWeight:"400"}} variant='body2'>{props.title}</Typography>
    </Badge>
}

const FilePreview = ({file, urlBase,title, ...rest}) => {
    return <div>

        {!IN_EDIT_MODE && <Typography style={{marginBottom:24, marginLeft:24, marginTop:24,textAlign:"center"}} variant='h6'>{title}</Typography>}
        {IN_EDIT_MODE && <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <Typography style={{marginBottom:24, marginLeft:24, marginTop:24}} variant='h6'>{title}</Typography>

        {file && !file.removed && <div style={{marginLeft:10}}>
            <Button onClick={() => {
                rest.onClick();
            }} variant='outlined' color='error'>Sil</Button>
        </div>}
        </div>}
        <div style={{display:"flex", justifyContent:"center"}}>
        
        {file && !file.removed && (file.path.endsWith(".png") || file.path.endsWith(".jpeg") || file.path.endsWith(".jpg")) && <img {...rest}  src={urlBase+file.path} />}
        {file && !file.removed && (file.path.endsWith(".pdf")) && <Document file={{url: urlBase+file.path}}>
        {/* <Page pageNumber={pageNumber} /> */}
      </Document>}
        
        {file && file.removed && <Typography variant='h6' color='crimson'>KALDIRILDI</Typography>}
        </div> 
    </div>
}

const DetailText = (props) => {
    return <div  style={{position:"relative",flexDirection:"column",display:"flex",paddingLeft:24, paddingTop:10, paddingBottom:10, borderBottom:"solid 1px #E6E8F0", borderTop:(props.first?"solid 1px #E6E8F0":"")}}>
        
<div style={{display:"flex",flex:1}}>
        <div style={{maxWidth:180,flex:.6, display:"flex", alignItems:(props.centerLabel)?"center":undefined}}>
        <Typography style={{fontWeight:"600"}}>{props.label}</Typography>
        </div>
        <div className='detailParent' style={{flex:1, alignItems:"center", justifyContent:"center"}}>
        {(!IN_EDIT_MODE || props.editable == false) && props.link == undefined && <Typography {...props}>{props.value}</Typography>}
        {(!IN_EDIT_MODE || props.editable == false) && props.link != undefined && <Link to={props.link} ><Typography {...props}>{props.value}</Typography></Link>}
        {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)}  sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
        {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()}
        {!IN_EDIT_MODE && props.children && {...props.children}}
        </div>
        {/* 


        {(!IN_EDIT_MODE || props.editable == false) && <Typography {...props} style={{position:"absolute", left:window.innerWidth < 500?"40%":"20%"}}>{props.value}</Typography>}
        {props.customEdit == undefined && IN_EDIT_MODE &&  props.editable != false && <TextField onChange={(e) => props.onChange(e.currentTarget.value)} style={{position:"absolute",right:24, left:"20%", padding:0}} sx={{padding:0, "& .MuiOutlinedInput-input": {paddingTop: "7px", paddingBottom:"7px", paddingLeft:"14px", paddingRight:"14px"}}}  value={props.value} />}
        {IN_EDIT_MODE && props.customEdit != undefined && props.customEdit()} */}

</div>

        {props.altComponent != undefined && props.altComponent}
    </div>
}

var dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}